@import ../../../../styles/helpers

.market
    margin: 0 -12px
    +t
        margin: 0

.table
    display: table
    width: 100%
    +t
        display: block

.row
    display: table-row
    +t
        display: none

.col
    position: relative
    display: table-cell
    padding: 0 12px 16px
    vertical-align: middle
    font-size: 14px
    color: $n4
    &:after
        content: ""
        position: absolute
        left: 0
        bottom: 0
        right: 0
        height: 1px
        background: $n3
        +dark
            background: $n6
    &:first-child
        width: 0px
        font-size: 0
        &:after
            left: 12px
    &:last-child
        text-align: center
        &:after
            right: 12px

.loader
    transform: scale(.8)
    margin: 0 18px 0 8px

.switcher
    margin-left: 30px
    display: flex
    justify-content: center
    align-items: center
    gap: 10px
    color: $n3

.foot
    display: flex
    justify-content: space-between
    align-items: center
    margin-top: 24px
    margin-right: 24px
    .field
        min-width: 300px
        margin-bottom: 0
    .transaction
        margin-left: 10px

.arrow
    width: 40px
    height: 40px
    border: 2px solid $n3
    border-radius: 50%
    transition: border-color .2s
    +dark
        border-color: transparent
    svg
        fill: $n4
        transition: fill .2s
    &:hover
        border-color: $n3
        svg
            fill: $n7
        +dark
            border-color: $n6
            svg
                fill: $n1
    &:not(:last-child)
        margin-right: 8px
